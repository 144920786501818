import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/circleci/choice/src/components/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div {...{
      "isBox": true,
      "flipKey": 129
    }}><h2 parentName="div">{`Ergebnisse aus dem ersten Teil des Choice-Projekts`}</h2>
      <p parentName="div">{`Die folgenden Ergebnisse beziehen sich auf den ersten Teil des Forschungsprojekts: dem Übergang von der Kita in die Grundschule. Sobald wir die Daten für den zweiten Teil des Projekts - den Übergang in die weiterführende Schule - erhoben und analyisiert haben, werden wir hier die wichtigsten Ergebnisse ebenfalls veröffentlichen.`}</p>
    </div><div {...{
      "isBox": true,
      "flipKey": 130
    }}>
      <h2 parentName="div">{`Wer hat teilgenommen?`}</h2>
      <p parentName="div">{`Für die Studie CHOICE haben insgesamt 1097 Eltern von Erstklässlerinnen und -klässlern sowie 201 Schulleitungen unsere Fragebögen ausgefüllt. Zudem konnten 24 Interviews mit Eltern und 18 Interviews mit Schulleiterinnen und Schulleitern geführt werden.`}</p>
      <readmore />
      <p parentName="div">{`Insgesamt haben 1097 Eltern, die ihr Kind zum Schuljahr 2015/16 eingeschult haben, unseren Frage-bogen ausgefüllt, wobei vor allem die Mütter an unserer Befragung teilgenommen haben (82% vs. 18% Väter). Von unseren befragten Eltern haben etwa 32% eine Privatschule für ihr Kind gewählt, 68% der Kinder besuchen eine öffentliche Grundschule. Der hohe Anteil an Kindern in unserer Stichprobe, die eine private Grundschule besuchen, ist durch unsere Stichprobenziehung und Fragestellung begründet und liegt über dem durchschnittlichen Anteil in Berlin (7,4% für das Schuljahr 2015/16 (ohne Waldorfschulen); Senatsverwaltung für Bildung, Jugend und Wissenschaft (2016): Blickpunkt Schule).`}</p>
      <p parentName="div">{`Insgesamt besuchen die Kinder unserer Stichprobe 119 verschiedene Schulen, was fast 25% der Berliner Grundschulen entspricht. Aufgrund der von uns ausgewählten Schulen, verteilen sich die Wohnorte der Kinder und Eltern, die an unserem Forschungsprojekt teilgenommen haben, nicht ganz gleichmäßig über Berlin, wie folgende Tabelle verdeutlicht:`}</p>
      <table>
  <tr>
    <th>Bezirk</th>
    <th className="is-number">Anteil aller beantworteten Fragebögen</th>
  </tr>
  <tbody>
    <tr>
      <td>Mitte</td>
      <td className="is-number">14,3%</td>
    </tr>
    <tr>
      <td>Friedrichshain-Kreuzberg</td>
      <td className="is-number">16,9%</td>
    </tr>
    <tr>
      <td>Pankow</td>
      <td className="is-number">18,7%</td>
    </tr>
    <tr>
      <td>Charlottenburg-Wilmersdorf</td>
      <td className="is-number">8,3%</td>
    </tr>
    <tr>
      <td>Spandau</td>
      <td className="is-number">1,8%</td>
    </tr>
    <tr>
      <td>Steglitz-Zehlendorf</td>
      <td className="is-number">11,1%</td>
    </tr>
    <tr>
      <td>Tempelhof-Schöneberg</td>
      <td className="is-number">6,9%</td>
    </tr>
    <tr>
      <td>Neukölln</td>
      <td className="is-number">5,0%</td>
    </tr>
    <tr>
      <td>Treptow-Köpenick</td>
      <td className="is-number">2,8%</td>
    </tr>
    <tr>
      <td>Marzahn-Hellersdorf</td>
      <td className="is-number">6,1%</td>
    </tr>
    <tr>
      <td>Lichtenberg</td>
      <td className="is-number">3,4%</td>
    </tr>
    <tr>
      <td>Reinickendorf</td>
      <td className="is-number">2,6%</td>
    </tr>
    <tr>
      <td>Brandenburg</td>
      <td className="is-number">2,2%</td>
    </tr>
  </tbody>
      </table>
      <p parentName="div">{`Neben den Eltern, wurden auch die Schulleitungen aller privaten und öffentlichen Grundschulen angeschrieben: Hier kamen 201 Fragebögen zurück, was 41 % aller Grundschulen in Berlin entspricht. Davon waren etwa 14% private und 86% öffentliche Schulen.`}</p>
      <p parentName="div">{`Zusätzlich wurden für den qualitativen Teil unserer Studie 24 Interviews mit Eltern und 18 Interviews mit Schulleitungen durchgeführt.`}</p>
      <p parentName="div">{`Wir bedanken uns bei allen Eltern und Schulen, die unser Forschungsprojekt CHOICE unterstützen!`}</p>
    </div><div {...{
      "isBox": true,
      "flipKey": 131
    }}>
      <h2 parentName="div">{`Was sind die Ursachen für den Anstieg der Privatschulzahlen?`}</h2>
      <p parentName="div">{`Seit 1992 steigt in Deutschland sowohl der Anteil der allgemeinbildenden Privatschulen als auch der Anteil der Schülerinnen und Schüler, die eine solche Schule besuchen. Für diese Entwicklung können verschiedene Ursachen verantwortlich gemacht werden: Neben zahlreichen Neugründungen privater Schulen vor allem in den ostdeutschen Bundesländern, werden das sinkende Vertrauen in staatliche Institutionen sowie eine größere Schulwahlfreiheit und höhere Ansprüche der Eltern an die Schule als bedeutsam angesehen.`}</p>
      <p parentName="div">{`Im Schuljahr 2013/14 besuchten deutschlandweit 8,7% aller Schülerinnen und Schüler eine allgemeinbildende Schule in freier Trägerschaft. Dabei gibt es jedoch große Unterschiede zwischen den Bundesländern:`}</p>
      <p parentName="div"><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAd4gsD//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAEFAl//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAXEAEAAwAAAAAAAAAAAAAAAAAQAREh/9oACAEBAAE/Idtl/9oADAMBAAIAAwAAABAzz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABkQAQADAQEAAAAAAAAAAAAAAAEAESEQMf/aAAgBAQABPxBVhAHYqtFw8gq3Ya1XP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/498dcdb9cb9b34726ad8bfc16a8adda2/ba381/image-01.webp 200w", "/static/498dcdb9cb9b34726ad8bfc16a8adda2/7f61c/image-01.webp 400w", "/static/498dcdb9cb9b34726ad8bfc16a8adda2/d00b9/image-01.webp 800w", "/static/498dcdb9cb9b34726ad8bfc16a8adda2/e46b2/image-01.webp 960w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/498dcdb9cb9b34726ad8bfc16a8adda2/e07e9/image-01.jpg 200w", "/static/498dcdb9cb9b34726ad8bfc16a8adda2/066f9/image-01.jpg 400w", "/static/498dcdb9cb9b34726ad8bfc16a8adda2/4b190/image-01.jpg 800w", "/static/498dcdb9cb9b34726ad8bfc16a8adda2/6a068/image-01.jpg 960w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/498dcdb9cb9b34726ad8bfc16a8adda2/4b190/image-01.jpg",
              "alt": "Anteil der Schüler an privaten Schulen",
              "title": "Anteil der Schüler an privaten Schulen",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p parentName="div">{`Daten: Statistisches Bundesamt (2014): Bildung und Kultur, Private Schulen (Schuljahr 2013/14). Eigene Berechnungen und Darstellung`}</p>
      <div parentName="div" {...{
        "isReadmore": true,
        "flipKey": 29
      }}>
        <p parentName="div">{`In den westdeutschen Flächenländern schwankt der Anteil an Privatschülerinnen und -schülern zwischen 4% und 12%, wobei nur wenige Kinder private Grundschulen besuchen (unter 4%). In den Stadtstaaten Hamburg, Bremen und Berlin sowie in den ostdeutschen Bundesländern gibt es im Gegensatz dazu einen hohen Anteil an Schülerinnen und Schülern, die an privaten Grundschulen (5% bis 11%), aber auch an weiterführenden Schulen in freier Trägerschaft unterrichtet werden. Die Unterschiede zwischen den Bundesländern sind teilweise darauf zurückzuführen, dass es in der ehemaligen DDR keine Privatschulen gab und diese erst nach der Wende dort in großer Zahl neu gegründet wurden. Jedoch kann der hohe Anteil an privaten Schulen – vor allem der Grundschulen – nicht allein durch diesen Nachholeffekt erklärt werden. Heute liegt der Anteil privater Schulen in Ostdeutschland deutlich über dem Niveau der westdeutschen Bundesländer.`}</p>
        <p parentName="div">{`Für die steigende Nachfrage nach privaten Schulen und insbesondere privaten Grundschulen in Ostdeutschland und den Stadtstaaten sind noch weitere Ursachen verantwortlich. So wurden vor allem in strukturschwachen und dünn besiedelten Gebieten in den späten 1990er und 2000er Jahren öffentliche Grundschulen geschlossen oder zusammengelegt, weil die Zahl schulpflichtiger Kinder abnahm. Mancherorts trat dann an die Stelle der öffentlichen Schule eine Privatschule, da diese keine Vorgaben wie beispielsweise eine Mindest-Klassengröße erfüllen muss. Ob diese neu gegründeten Privatschulen jedoch dauerhaft bestehen bleiben, ist fraglich, denn auch diese benötigen genügend Schülerinnen und Schüler für den Schulbetrieb.`}</p>
        <p parentName="div">{`Ein weiterer Grund für den stetigen Anstieg privater Schulen könnte sein, dass die Menschen immer weniger den staatlichen Institutionen vertrauen, wozu auch die Schule zählt. Diese Entwicklung wurde beispielsweise durch das schlechte Abschneiden deutscher Schülerinnen und Schüler in Vergleichsstudien wie PISA noch verstärkt. Jedoch kann PISA nicht als der (alleinige) Auslöser für den „Privatschulboom“ angesehen werden, da es bereits vor der Veröffentlichung der ersten PISA-Ergebnisse einen starken Zuwachs an Privatschulen gab.`}</p>
        <p parentName="div">{`Schließlich unterscheiden sich Eltern hinsichtlich ihrer Erziehungsvorstellungen immer mehr voneinander, was eine andere Ursache für die Zunahme von Privatschulen sein könnte. Diese unterschiedlichen Ansprüche können Privatschulen mit ihrer Vielfalt an pädagogischen Konzepten und Schwerpunkten gut bedienen, auch wenn öffentliche Schulen hier ebenfalls ein immer größeres Angebot und unterschiedliche Profile entwickeln.`}</p>
        <p parentName="div">{`Detailliertere Ergebnisse dazu finden Sie in: Koinzer, Thomas; Mayer, Tanja (2015). Private Schulen - Entwicklung und empirische Befunde unter besonderer Berücksichtigung des Grundschulwesens. Zeitschrift für Grundschulforschung, 8 (2015), Heft 2, S.28-41. Ebenfalls veröffentlicht auf der Homepage der Böll-Stiftung: `}<a parentName="p" {...{
            "href": "https://www.boell.de/sites/default/files/uploads/2016/08/artikel_grundschulforschung.pdf"
          }}>{`https://www.boell.de/sites/default/files/uploads/2016/08/artikel`}{`_`}{`grundschulforschung.pdf`}</a></p>
      </div></div><div {...{
      "isBox": true,
      "flipKey": 132
    }}>
      <h2 parentName="div">{`Was sind die Gründe für die Abkehr aus dem öffentlichen Schulsystem?`}</h2>
      <p parentName="div">{`In den von uns durchgeführten Interviews mit Eltern, die ihr Kind an einer privaten Grundschule eingeschult haben, konnten wir drei Gründe für die Abkehr aus dem öffentlichen Schulsystem identifizieren: Die Schulbiografie der Eltern, Schulerfahrungen älterer eigener Kinder und im Prozess der Schulwahl eingeholte (negative) Informationen über die öffentliche Schule im Einzugsgebiet. Diese drei Gründe führten bei den interviewten Privatschuleltern in einem ersten Schritt dazu, dass sie eine Alternative zur öffentlichen Schule suchen. In einem weiteren Schritt wird dann – nach Abwägung weiterer Kriterien und Motive – eine konkrete Privatschule für das Kind ausgewählt.`}</p>
      <div parentName="div" {...{
        "isReadmore": true,
        "flipKey": 30
      }}>
        <ol parentName="div">
          <li parentName="ol">
            <p parentName="li">{`Eltern, die in ihrer eigenen Schulzeit schlechte Erfahrungen mit öffentlichen Schulen gemacht haben, suchen für ihr eigenes Kind nach Alternativen. Eine Möglichkeit kann hier eine Privatschule sein, mit der die Hoffnung verknüpft ist, dass das Kind eine bessere Schulzeit haben wird. Ebenfalls können positive Erfahrungen der Eltern in ihrer eigenen Schulzeit an einer Privatschule dazu führen, dass sie für das eigene Kind eine solche Schule wählen. Hier können also auch Familientraditionen eine Rolle spielen.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Schulerfahrungen älterer Geschwisterkinder können dazu führen, dass Eltern sich für eine Privatschule entscheiden. Vor allem wenn ältere Geschwister an öffentlichen Schulen Probleme hatten oder es andere negative Vorerfahrungen in der Familie gab, suchen diese Eltern nach Alternativen zur staatlichen Beschulung. Andererseits zeigt sich, dass wenn für ältere Kinder bereits eine Privatschule gewählt wurde und die Eltern damit zufrieden waren, diese Wahl bei jüngeren Kindern wiederholt wird.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`(Negative) Informationen, die die Eltern kurz vor der Einschulung ihres Kindes an einer öffentlichen Schule erhalten, können dazu beitragen, dass sie sich noch einmal umentscheiden. Da ein Wechsel an eine andere öffentliche Schule so kurz vor Schuljahresbeginn dann nicht mehr möglich ist, bleibt den Familien nur noch die Wahl einer Privatschule. Diese können Schüler und Schülerinnen auch kurzfristig aufnehmen, weil sie nicht an vorgegebene Fristen und Aufnahmeregelungen gebunden sind.`}</p>
          </li>
        </ol>
        <p parentName="div">{`Diese drei Auslöser wurden uns von den interviewten Eltern berichtet. Das bedeutet jedoch nicht, dass diese Auslöser immer zur Wahl einer Privatschule führen müssen. Auch können andere Auslöser für die Wahl einer Privatschule möglich sein – hierfür müsste man noch mehr Eltern befragen.`}</p>
      </div></div><div {...{
      "isBox": true,
      "flipKey": 133
    }}>
      <h2 parentName="div">{`Wie informieren sich die Eltern über die Schulen?`}</h2>
      <p parentName="div">{`Die Befragung CHOICE hat gezeigt, dass Eltern, die aktiv eine Schule für ihr Kind wählen (im Folgenden „Wähler“), sich vor allem über die Homepages der Schulen informieren. Zudem greifen sie auf Erfahrungen von Freunden und Bekannten sowie auf Angebote zurück, die ihnen die Möglichkeit geben, sich einen persönlichen Eindruck von der Schule zu machen (z.B. Tag der offenen Tür). Aber auch Eltern, die sich für die zugewiesene Schule entscheiden, ohne andere Schulen in ihre Entscheidung miteinzubeziehen (im Folgenden „Nicht-Wähler“), informieren sich über die Schule ihres Kindes. Dafür nutzen sie die gleichen Informationsquellen wie die Wähler. Allerdings zeigen sich Unterschiede hinsichtlich der Bandbreite der genutzten Informationsquellen, die für die Schulwahl herangezogen werden.`}</p>
      <div parentName="div" {...{
        "isReadmore": true,
        "flipKey": 31
      }}>
        <p parentName="div">{`Schulwahl ist in Bezug auf die Grundschule in Berlin nur sehr eingeschränkt möglich, denn jedes Kind bekommt vor der Einschulung eine öffentliche Grundschule zugewiesen. Wollen Eltern eine andere Grundschule wählen, müssen sie dies zum Beispiel mit einem besonderen Profil oder speziellen Betreuungsangebot an einer anderen öffentlichen Schule begründen oder auf eine Privatschule zurückgreifen. Um sich über die Schulen zu informieren, greifen Eltern auf unterschiedliche Informationsquellen zurück. Die folgende Abbildung zeigt, welche Informationsquellen sowohl Wähler, die sich aktiv für eine Schule entschieden haben, als auch Nicht-Wähler nutzen.`}</p>
        <p parentName="div"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "65.49999999999999%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABcRAAAXEQHKJvM/AAACIElEQVQ4y42T7W/SYBTF+ac1brqpmZ+ciSZGv5jo5uY0ugFjxqlkUefrApnL/CCDFdQYB7S0hZZCX+jxngdW68wSn3C4fQj3l3NfmnFdNw6CAL7vx4HvI4oi9Ho9GK0mTF0XtdWzZehwbAumxPbRLwQDDxiNEEchwiBQDLIyfJAT8ysMQ4KVdrXv+FDR8OmgnsSdamN8/3qIsvYDtZaJStOA7Q0Vg3kZOuNNnMWdTgfN5hEs28a13AucufcE0/ezmFrM4vxSDhdSmlnOYXO/infaT3ScvmIMBoM443keHMeBZUk5pgnDMNCReOvpFi4u5zH3aANzDwuYFci5hVWlKdHZu49xNfcShdIXBFIZz3A4hAISorNfEyAd3iwUBZLFFYFdepDH6vsy9rVv2K3W8blWx16tgVLlUGJd9T0Bsu5ut6tgdEnZcr+xXsSMAKcX13BZymtZNk47Iw7nGMihsGT2T0FFXZny7WevMbuyjoU3Jbw9aCAIQpUYipsoJf5GJQ7piGWq3k2gBF4Xh/P5Ij5K00MmxLF84rGbSUw/J0CZjFoX2SG027Jz0kvVw40tLG2XoemWJI0T/xvIHirYZDjs4fzac6xsl/706ATwJDwBHg/DFlcU747j4s7mKxTLe/8A0+7SQEaaO35T/jpsQRQG6Am435c9dRn7iZjDZIquGLktNKTWRr03KQcUj+V60Dkw05JlHy8+e33a/32B/wZ7VMXpwo+e5wAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/80d01dd439fc563c1d52c46fd9a95e2f/ba381/Informationsquellen_W_NW.webp 200w", "/static/80d01dd439fc563c1d52c46fd9a95e2f/7f61c/Informationsquellen_W_NW.webp 400w", "/static/80d01dd439fc563c1d52c46fd9a95e2f/d00b9/Informationsquellen_W_NW.webp 800w", "/static/80d01dd439fc563c1d52c46fd9a95e2f/02506/Informationsquellen_W_NW.webp 1040w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/80d01dd439fc563c1d52c46fd9a95e2f/772e8/Informationsquellen_W_NW.png 200w", "/static/80d01dd439fc563c1d52c46fd9a95e2f/e17e5/Informationsquellen_W_NW.png 400w", "/static/80d01dd439fc563c1d52c46fd9a95e2f/5a190/Informationsquellen_W_NW.png 800w", "/static/80d01dd439fc563c1d52c46fd9a95e2f/1ff84/Informationsquellen_W_NW.png 1040w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/80d01dd439fc563c1d52c46fd9a95e2f/5a190/Informationsquellen_W_NW.png",
                "alt": "genutze Informationsquellen von Wählern und Nicht-Wählern",
                "title": "genutze Informationsquellen von Wählern und Nicht-Wählern",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
      `}</picture>{`
    `}</span></p>
        <p parentName="div">{`Quelle: Eigene Daten`}</p>
        <p parentName="div">{`Auch wenn auf den ersten Blick kaum Unterschiede zwischen den Wählern und Nicht-Wählern zu sehen sind, so zeigt sich doch, dass die Wähler für ihre Schulwahlentscheidung zum einen eine größere Anzahl unterschiedlicher Informationsquellen nutzen als die Nicht-Wähler, und zum anderen auch aktiver bei der Informationsbeschaffung agieren, d.h. häufiger die Möglichkeit eines Schulbesuchs nutzen oder eigenständige Recherchen im Internet durchführen. Daraus lässt sich schließen, dass die Schulwahl der Wähler auf (scheinbar) objektiveren Informationen beruht. Nicht-Wähler verlassen sich hingegen auf subjektivere Informationen wie Empfehlungen von Freunden, Erziehern oder Elternabende im Kindergarten. Die Unterschiede zwischen den beiden Gruppen zeigen somit auch, dass die Schulwahl der Wähler rationalen Abwägungen unterliegt, wohingegen Nicht-Wähler eine eher zufällige Schulwahlentscheidung treffen.`}</p>
        <p parentName="div">{`Detailliertere Ergebnisse hierzu finden Sie in:`}<br parentName="p"></br>{`
`}{`Schwarz, Judith; Habeck, Corinna; Gruehn, Sabine; Koinzer, Thomas (2017): School Choice in German Primary Schools. In: Koinzer, Thomas; Nikolai, Rita; Waldow, Florian (Hg.): Private Schools and School Choice in Compulsory Education. Wiesbaden: Springer Verlag, S.177-199. Online verfügbar unter: `}<a parentName="p" {...{
            "href": "https://link.springer.com/chapter/10.1007/978-3-658-17104-9_11"
          }}>{`https://link.springer.com/chapter/10.1007/978-3-658-17104-9`}{`_`}{`11`}</a></p>
      </div></div><div {...{
      "isBox": true,
      "flipKey": 134
    }}>
      <h2 parentName="div">{`Welche Gründe sind für die Wahl einer Grundschule allgemein ausschlaggebend?`}</h2>
      <p parentName="div">{`Hinsichtlich der Schulwahlgründe betonen Eltern, die aktiv eine Schule für ihr Kind wählen (im Folgenden „Wähler“), vor allem Aspekte der Schulqualität und unterscheiden sich dadurch von den Eltern, die keine Alternative zur zugewiesenen Schule gesucht haben (im Folgenden „Nicht-Wähler“).`}</p>
      <div parentName="div" {...{
        "isReadmore": true,
        "flipKey": 32
      }}>
        <p parentName="div">{`Im Fragebogen wurden die Eltern dazu aufgefordert, die Bedeutung von vierzehn unterschiedlichen Schulwahlkriterien auf einer Skala von 1 (sehr unwichtig) bis 4 (sehr wichtig) zu bewerten. In der folgenden Abbildung sind die Mittelwerte der beiden Gruppen abgetragen, wodurch die Gemeinsamkeiten und Unterschiede sichtbar werden.`}</p>
        <p parentName="div"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "97.00000000000001%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAABcRAAAXEQHKJvM/AAADD0lEQVQ4y3WU+U8TURDH+YP9xRiicralrTGCgPEHTIwgylEp9KBclkjiD1xKEOQQiFxpS+l2t8f23Jb9OvP2oBR8yWTebvs+OzPfmddSUFVd0zRUKxW9Wq1CWKWCWq2GQqGAeCwGKZmEnLpGRpJQq5TRuMRZ6xxZC7+gpaNp3dzcQKM/5FUVv/5eYPPoFD+Pz7B9eondizh2zmPYv0xAow83rhai6vV6nem69TX22VwOcjqNpJTGS/8Cno340fEpgLaP02gnezrsx8DMAnJqQYB0XQdzRISlUgkSpSPLsu0zmQxyBE3JCvpDy+iemEWPbw6uyQjcX+bQNR5GdGvXhllZCSCT8/k8isUistms2KuUqko+pWTQF/yKrrEQnAR1EMg1OYu20QBW9o4NUCOQU2xcLATDGMxAKZPFYDgK10QYHh9FRzAvRegcD+Hd4ncbxlHeAeoNP3CqDMyRSRRhf2AJ3Z8DcBHEORYUns1B+8jalh2MqGEj0DL+EgMVqmUyLZvAoIjSacJ4303AV/55LK7+wFn8ylZZQFgENkVRDM/PtL8m4GuqIR+2geR7KPX20RnsUBtZ9XuwhlboQhxT5YHwMhwkiNtUmdXmPYvUG4xCrWj/T5lfcg0NM2rYR4e4TZwEY7CDonOa1knqrx2eCEatEdg8TqIPKUpOuXd6AR2UHovQRc3NArHx83Nq+KHI8l2VeW5tZU2fJkFEH3LKwSWhrptrRzV0TxjmoefO0WlEt/dvU66Zs8ieI2PPP/D0WKIMhow+9Jp96PHNij2r/XZuBedK/jZlVpWjsqaDPRu/UxRZ1PBN5JsYtxc0096peXjJ895D+z4ay5GVdaRzBrSFx40jq9CVZUXIQE6Za5iUZGqRCB6/96GVLoTW4SnTG/bkwxQeDY3h4DJuAM3r617b8Ae4vqVyGdsnp9ggJTfJVn8fYn3/mOwIa2x7f7BxcIJ8sXzb2M2T0gwvqnRx5HOolop4aNW1KtJ0SyUSifvAxrlWCkXIVGOJLJa4QiqVEnXmC6FuTgbvNa1m39z/AOKhUH4VEkbfAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/b2053f20bbca07ba2a59eece6cd07d41/ba381/Schulwahlkriterien.webp 200w", "/static/b2053f20bbca07ba2a59eece6cd07d41/7f61c/Schulwahlkriterien.webp 400w", "/static/b2053f20bbca07ba2a59eece6cd07d41/d00b9/Schulwahlkriterien.webp 800w", "/static/b2053f20bbca07ba2a59eece6cd07d41/43c62/Schulwahlkriterien.webp 1034w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/b2053f20bbca07ba2a59eece6cd07d41/772e8/Schulwahlkriterien.png 200w", "/static/b2053f20bbca07ba2a59eece6cd07d41/e17e5/Schulwahlkriterien.png 400w", "/static/b2053f20bbca07ba2a59eece6cd07d41/5a190/Schulwahlkriterien.png 800w", "/static/b2053f20bbca07ba2a59eece6cd07d41/709cb/Schulwahlkriterien.png 1034w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/b2053f20bbca07ba2a59eece6cd07d41/5a190/Schulwahlkriterien.png",
                "alt": "Schulwahlkriterien von Wählern und Nicht-Wählern",
                "title": "Schulwahlkriterien von Wählern und Nicht-Wählern",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
      `}</picture>{`
    `}</span></p>
        <p parentName="div">{`Quelle: Eigene Daten`}</p>
        <p parentName="div">{`Unabhängig davon, ob die Eltern die Schule aktiv gewählt haben oder nicht, zeigen die Daten, dass für alle Eltern die verschiedenen Schulwahlkriterien sehr wichtig sind. Zudem wird deutlich, dass nicht ein einzelner Grund für die Schulwahl ausschlaggebend ist, sondern viele verschiedene. Die drei wichtigsten Motive für die Wähler sind die Atmosphäre der Schule (3,63), die Unterstützung der Persönlichkeit des Kindes (3,59) und das Profil der Schule (3,50). Für Nicht-Wähler ist stattdessen die Nähe zur Schule (3,71) das wichtigste Motiv, gefolgt von der Unterstützung der Persönlichkeit des Kindes (3,67) und der Schulatmosphäre (3,65). Weniger wichtig ist allen Eltern ein hoher Leistungsstandard, wenige Problemkinder in der Schule, die Ausstattung der Schule oder dass Freunde des Kindes dieselbe Schule besuchen.`}</p>
        <p parentName="div">{`Insgesamt zeigt sich, dass den befragten Grundschuleltern vor allem ein Erziehungskonzept wichtig ist, das die Kinder und deren Entwicklung in den Mittelpunkt stellt.`}</p>
        <p parentName="div">{`Detailliertere Ergebnisse hierzu finden Sie in:`}<br parentName="p"></br>{`
`}{`Schwarz, Judith; Habeck, Corinna; Gruehn, Sabine; Koinzer, Thomas (2017): School Choice in German Primary Schools. In: Koinzer, Thomas; Nikolai, Rita; Waldow, Florian (Hg.): Private Schools and School Choice in Compulsory Education. Wiesbaden: Springer Verlag, S.177-199. Online verfügbar unter: `}<a parentName="p" {...{
            "href": "https://link.springer.com/chapter/10.1007/978-3-658-17104-9_11"
          }}>{`https://link.springer.com/chapter/10.1007/978-3-658-17104-9`}{`_`}{`11`}</a></p>
      </div></div><div {...{
      "isBox": true,
      "flipKey": 135
    }}>
      <h2 parentName="div">{`Welche Eltern wählen eine private Schule für ihr Kind und welche eine öffentliche Schule? Wie begründen Eltern ihre Schulwahl?`}</h2>
      <p parentName="div">{`Eltern, die sich gegen ihre Einzugsgrundschule entschieden haben, wählen sowohl private Schulen als auch andere öffentliche Schulen aktiv aus. Darüber hinaus hat die Hälfte der Eltern, deren Kind in eine öffentliche Grundschule eingeschult wurde, während des Schulwahlprozesses auch über eine private Schule nachgedacht. Bezüglich des Informationsverhaltens und der Schulwahlgründe zeigen sich zwischen den beiden Elterngruppen einige Unterschiede.`}</p>
      <div parentName="div" {...{
        "isReadmore": true,
        "flipKey": 33
      }}>
        <p parentName="div">{`Im Durchschnitt haben sich alle Eltern vor allem durch persönliche Eindrücke (z.B. am Tag der offenen Tür), Schulhomepages und Erfahrungen von Freunden und Verwandten über drei bis vier Schulen informiert. Privatschuleltern greifen lediglich häufiger auf Informationsbroschüren zurück, die vermutlich von Privatschulen häufiger angeboten werden.`}</p>
        <p parentName="div">{`Welche Aspekte spielen nun eine wichtige Rolle bei der Schulwahl? Die Studie zeigt, dass deutliche Unterschiede zwischen beiden Elterngruppen bestehen: Eltern an öffentlichen Grundschulen betonen vor allem pragmatische Kriterien (z.B. Nähe der Schule, Wunsch des Kindes) bei ihrer Schulwahl. Privatschuleltern führen im Gegensatz dazu größtenteils Charakteristika der einzelnen Schule (z.B. Schulprofil, Schulklima, Ausstattung) an. Im Folgenden sind die Kriterien abgebildet, bei denen sich statistisch signifikante Unterschiede gezeigt haben:`}</p>
        <p parentName="div"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "64.5%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABcRAAAXEQHKJvM/AAACjElEQVQ4y2NgwA84gFgBiJWAWBnKFsCluLOzE4XPCsQsINrZ2Znp4MGDjGZmZiwiIiLcICwsLMwjKirKraSkxKYbl8v6f44Dg111L7NjdQ/L8amtjHEN3SiGMQGxKBDzALGglZUV65w5c5jnzZvH/P//f0YkzLBq6w5mSXsvibzOiaxKJb3SNlU9XHdmtjAF16EayAj1iiwQywBdwwrSXFhYyFhUVMQIolOzc4AG/mDgt/UWk0yrFVJqnMfnVtfPvLIgnjGnoY3xwcZFKAaCvCoOxKpArA70FseZM2eY6urqWIDhwtzR0QHGXd3dzBJO/pIS8aVaiikVsnUFOcwgzfNnzWCOyczFGq58IJdqamoyb9u2jbG+vp4RaCAYt7W3M/b39jDCFEoGpwqJReUpCAany1k6uYENZlAwxWogv6OjIwsoDFtaWphhLoTRJdV1LIVdE0E+YrBumcUjGp6tY+/lxw7WzasGNwhkgzA0UnidnJxYZ86cydLY2MgCNISlra0N5HWWttZWlsa2DpbupnqwgdKpNepS/vGKILZGeCojeqTwQg3k5uHhYZo0aRIDKGJKSkrACsrLy+GKrVafZRRJqpISCE6XEXIOYqn3tmbQyqrFSDZCoKABYkUHBwd2oGFMBQUF7JMmT2bJyMhg758wkSUsJISjpaePVSqxXEfRL0b2elkk8861K5mv3LzFPHlCP9ZIYYJ5G+hkTkZGRhF2NjYmVjY2Zqg3OLhFJZiYIOoYmNk5OBmZmASB6vigGQM7YGTn5GJgYRWBJnYOsKCEIgOHsQMTNHiYmOTUGKFhDzJMEKuBgrHFYFp2xl4uLnMXSbCBzMwgg8VZJOU5uUydpKDpVQyIRbA5BgBaaa8VcvTMBgAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/762bd8ed04954ad01a322534b5519212/ba381/SchulwahlkriterienOESPS.webp 200w", "/static/762bd8ed04954ad01a322534b5519212/7f61c/SchulwahlkriterienOESPS.webp 400w", "/static/762bd8ed04954ad01a322534b5519212/d00b9/SchulwahlkriterienOESPS.webp 800w", "/static/762bd8ed04954ad01a322534b5519212/fd213/SchulwahlkriterienOESPS.webp 994w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/762bd8ed04954ad01a322534b5519212/772e8/SchulwahlkriterienOESPS.png 200w", "/static/762bd8ed04954ad01a322534b5519212/e17e5/SchulwahlkriterienOESPS.png 400w", "/static/762bd8ed04954ad01a322534b5519212/5a190/SchulwahlkriterienOESPS.png 800w", "/static/762bd8ed04954ad01a322534b5519212/75609/SchulwahlkriterienOESPS.png 994w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/762bd8ed04954ad01a322534b5519212/5a190/SchulwahlkriterienOESPS.png",
                "alt": "Schulwahlkriterien von Öffentlichen und Privatschuleltern",
                "title": "Schulwahlkriterien von Öffentlichen und Privatschuleltern",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
      `}</picture>{`
    `}</span></p>
        <p parentName="div">{`Quelle: Eigene Daten`}</p>
        <p parentName="div">{`In Bezug auf die Schulqualität wird deutlich, dass Privatschuleltern das Leistungsniveau, das pädagogische Konzept und die Ausstattung der Schule höher einschätzen. Hingegen bestehen keine Unterschiede bei leistungsbezogenen Schulwahlgründen (z.B. Ruf der Schule, hoher Leistungsstandard), was auf die insgesamt sehr bildungsorientierte Elternschaft in dieser Untersuchung zurückzuführen ist.`}</p>
        <p parentName="div">{`Detailliertere Ergebnisse hierzu finden Sie in:`}<br parentName="p"></br>{`
`}{`Habeck, Corinna; Schwarz, Judith; Gruehn Sabine; Koinzer, Thomas (2017): Public and Private School Choice in the German Primary Education Sector: An Empirical Analysis of Parental Reasons. In: Koinzer, Thomas; Nikolai, Rita; Waldow, Florian (Hg.): Private Schools and School Choice in Compulsory Education. Wiesbaden: Springer Verlag, S.177-199. Online verfügbar unter: `}<a parentName="p" {...{
            "href": "https://link.springer.com/chapter/10.1007/978-3-658-17104-9_12"
          }}>{`https://link.springer.com/chapter/10.1007/978-3-658-17104-9`}{`_`}{`12`}</a></p>
      </div></div><div {...{
      "isBox": true,
      "flipKey": 136
    }}>
      <h2 parentName="div">{`Welche Rolle spielt die Schulqualität bei der Wahl einer Grundschule?`}</h2>
      <p parentName="div">{`Verschiedene Studien stellten fest, dass Eltern an Privatschulen unterschiedliche Schulqualitätsaspekte (z.B. Schulklima, Engagement der Lehrer) besser bewerten als Eltern, deren Kind eine öffentliche Schule besucht. Dieses Ergebnis wird durch unsere Befragung zur Grundschulwahl bestätigt. Es zeigt sich, dass die Schulwahlentscheidung für eine Privatschule mit hohen Erwartungen bezüglich der Schulqualität einhergeht. Allerdings zeigt die Nachbefragung, dass die positive Einschätzung der Schulqualität in der Konfrontation mit der Schulrealität abnimmt.`}</p>
      <div parentName="div" {...{
        "isReadmore": true,
        "flipKey": 34
      }}>
        <p parentName="div">{`Um die Wahlentscheidung von Eltern besser nachvollziehen zu können, wurden im Rahmen des Forschungsprojektes CHOICE Eltern von Erstklässlern an öffentlichen und privaten Grundschulen nicht nur zu Beginn, sondern auch am Ende des ersten Schuljahres befragt. Erste Ergebnisse der zweiten Befragung zeigen, dass die Mehrheit aller Eltern (68%) mit der Schule ihres Kindes zufrieden ist. Auch die Erwartungen, die die Eltern vor der Einschulung mit der Schule ihres Kindes verbunden haben, wurden größtenteils erfüllt. Allerdings decken sich die Erwartungen der Eltern, deren Kind eine öffentliche Schule besucht, seltener mit der vorgefundenen Situation als bei den Eltern, die sich für eine Privatschule entschieden haben. Zudem haben Letztere auch insgesamt höhere Erwartungen an die Schule.`}</p>
        <p parentName="div">{`Bezogen auf die Schulqualität zeigt sich, dass Eltern, die sich für eine Privatschule entschieden haben, beispielsweise das Leistungsniveau, den Zustand der Schule und die pädagogische Arbeit sowohl vor als auch nach der Einschulung deutlich höher einstufen als Eltern, deren Kind eine öffentliche Schule besucht. Allerdings sind die positiven Einschätzungen der verschiedenen Schulqualitätsaspekte in beiden Elterngruppen nach der Einschulung zurückgegangen.`}</p>
        <p parentName="div">{`Im Folgenden sind die Mittelwerte von drei Faktoren dargestellt, die jeweils auf Basis verschiedener Schulqualitätsaspekte gebildet wurden. Der Mittelwert der zum Punkt 1 angegeben ist, bezieht sich auf die Angaben der Eltern zum ersten Befragungszeitraum (Einschulung), der zweite Mittelwert (Punkt 2) auf die Nachbefragung (Ende erstes Schuljahr). Die Antwortskala reicht dabei von 1 „stimme überhaupt nicht zu“ bis 4 „stimme voll und ganz zu“.`}</p>
        <p parentName="div"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "44.99999999999999%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABcRAAAXEQHKJvM/AAABS0lEQVQoz6VSXZODMAjM//+Debq5u9pqTNQav6rWSHdpe2OfjxkkICwLg1nXVSgpJdm2TW3btpLnuTjnpCgKybJMLpeLnKGM8329XjX3fr9Td4jEGHfD4rqqZBwGWZZFQcdxlKZppK4rLTyqQwOCMuctBKMZgGG6rpMWiXRYsJAxEuZlld8zmMQoK1jMaMZYCz+hKUFeQArGDzB2Q9pv6fteWe6IxfEmX3kpJ1/Jt/Py44L65zLIMt90EgLScmy+Sc7QofAHx+dONQnavBizKXVFrG4jGndaM0w3KatavPcSQtCdfzDkXt57pMqBwTP2ZKIN4Pfzqoyz00nXpTsEciryPJVlmcAwgSFytz8F2Ic9vnkcWFPCFagPwGQOi/23cBIDRhZvizEsuqhFVxu8t6VzdpomjSHZ4rxsFYLFVBarsdurhv9AzOIO7QPjAbf40Ry4jwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/888deae43698b6b512cd9152b4ec92ac/ba381/Quali1_Rahmenbedingungen.webp 200w", "/static/888deae43698b6b512cd9152b4ec92ac/7f61c/Quali1_Rahmenbedingungen.webp 400w", "/static/888deae43698b6b512cd9152b4ec92ac/d00b9/Quali1_Rahmenbedingungen.webp 800w", "/static/888deae43698b6b512cd9152b4ec92ac/6749f/Quali1_Rahmenbedingungen.webp 878w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/888deae43698b6b512cd9152b4ec92ac/772e8/Quali1_Rahmenbedingungen.png 200w", "/static/888deae43698b6b512cd9152b4ec92ac/e17e5/Quali1_Rahmenbedingungen.png 400w", "/static/888deae43698b6b512cd9152b4ec92ac/5a190/Quali1_Rahmenbedingungen.png 800w", "/static/888deae43698b6b512cd9152b4ec92ac/94829/Quali1_Rahmenbedingungen.png 878w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/888deae43698b6b512cd9152b4ec92ac/5a190/Quali1_Rahmenbedingungen.png",
                "alt": "Schulqualität Mittelwerte zu den schulischen Rahmenbedingungen",
                "title": "Schulqualität Mittelwerte zu den schulischen Rahmenbedingungen",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
      `}</picture>{`
    `}</span></p>
        <p parentName="div">{`Quelle: Eigene Daten`}</p>
        <p parentName="div"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "43.50000000000001%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABcRAAAXEQHKJvM/AAABNklEQVQoz3VSCW6EMAzk/0+senAuxwIJRwuEY+rJYqlFu5aGoMgee8YJ9n3Htm3/sK4r8jxHkiRI0xRFUSDLMkRRhLZtwRrmPEPAj5LwZDJhjcFNSO5Vhb7r0FmDtq7R973PO44DzyKIpWsh0zRNg3lZsAnx4la8hTE+4hR1N6BsLSpjkTdCKo2WefZNSXpFoBON44hZEtndCd7TG+Kiwr0XQtujEqRCaKzFIo1fTqg/VhJJ6Jzz8o9dZFEaJ9kfJ87GJGTjxnYoxRJ6TLA28L4JhmHwhGquE9luvUAK/vo9TjN+pgmTgLVecinMNN6eUjT5FbQhbaqHb3x+hQjDUEjnh2RPcsrULXMS3qu/eq9S/UJogYSRJVEdw094NZVbZlIjT4RTa+Igz8XIGyzL0m9Z7zX0/xeV6ry3n507IAAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/6072057da1418cb610009af4791e3ac7/ba381/Quali2_paedAspekte.webp 200w", "/static/6072057da1418cb610009af4791e3ac7/7f61c/Quali2_paedAspekte.webp 400w", "/static/6072057da1418cb610009af4791e3ac7/d00b9/Quali2_paedAspekte.webp 800w", "/static/6072057da1418cb610009af4791e3ac7/dad35/Quali2_paedAspekte.webp 885w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/6072057da1418cb610009af4791e3ac7/772e8/Quali2_paedAspekte.png 200w", "/static/6072057da1418cb610009af4791e3ac7/e17e5/Quali2_paedAspekte.png 400w", "/static/6072057da1418cb610009af4791e3ac7/5a190/Quali2_paedAspekte.png 800w", "/static/6072057da1418cb610009af4791e3ac7/efc66/Quali2_paedAspekte.png 885w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/6072057da1418cb610009af4791e3ac7/5a190/Quali2_paedAspekte.png",
                "alt": "Schulqualität Mittelwerte zu den pädagogischen Aspekten",
                "title": "Schulqualität Mittelwerte zu den pädagogischen Aspekten",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
      `}</picture>{`
    `}</span></p>
        <p parentName="div">{`Quelle: Eigene Daten`}</p>
        <p parentName="div"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "43%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABcRAAAXEQHKJvM/AAABPklEQVQoz21SB27DMAz0/5/WBwToSIoE8lLseC95XHmsVQRuBBCUaPJ4RzrYtg3LumJZFjW+i6JAGIaIogjGGLU4jpEkCa7XG+q6xio18zz/s6BtGozDAOccpmlS0LIskaapAlprEQl4nufIskwbNVLDvFcnSKRz8XjgIdb3PcZxVHACVlWFSe6MDTRpzLxhJ0A1Rwv8hV0JSNpV2+Ht9IH3m8GXifFpIvWnq0GUWgyS5yRPz17/x/A3tqnMrus0uRcGiRSWwpD3TmK0XlhylpTPxo3ErIzhfreqiKQCP0w+FFAAaJznuMt8NidxyqcvugFnUfF9uSigSuYM+eBmn4Fe2fM35yZQaCZsuTxuXSWTHTdGz0HTa+G+ccZ83LOc9/fxKMNjkMXc+l1+l0b+N3/attWtW1HDhh7AL8T7H3oIuPlLfIn+AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/ddea63f541e572b6706545cc8b4c95a7/ba381/Quali3_Leistung.webp 200w", "/static/ddea63f541e572b6706545cc8b4c95a7/7f61c/Quali3_Leistung.webp 400w", "/static/ddea63f541e572b6706545cc8b4c95a7/d00b9/Quali3_Leistung.webp 800w", "/static/ddea63f541e572b6706545cc8b4c95a7/1938b/Quali3_Leistung.webp 887w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/ddea63f541e572b6706545cc8b4c95a7/772e8/Quali3_Leistung.png 200w", "/static/ddea63f541e572b6706545cc8b4c95a7/e17e5/Quali3_Leistung.png 400w", "/static/ddea63f541e572b6706545cc8b4c95a7/5a190/Quali3_Leistung.png 800w", "/static/ddea63f541e572b6706545cc8b4c95a7/eac55/Quali3_Leistung.png 887w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/ddea63f541e572b6706545cc8b4c95a7/5a190/Quali3_Leistung.png",
                "alt": "Schulqualität Mittelwerte zu den leistungsbezogenen Aspekten",
                "title": "Schulqualität Mittelwerte zu den leistungsbezogenen Aspekten",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
      `}</picture>{`
    `}</span></p>
        <p parentName="div">{`Quelle: Eigene Daten`}</p>
        <p parentName="div">{`Im Gegensatz zu Privatschuleltern kritisieren Eltern, deren Kind eine öffentliche Schule besucht, häufiger die mangelhafte technische Ausstattung, die räumlichen Gegebenheiten der Schulen sowie das teilweise raue Schulklima. Diese Unterschiede spiegeln sich auch in den offen erfragten Verbesserungsvorschlägen wider: Eltern von Erstklässlern an öffentlichen Schulen nannten hier vor allem materielle und personelle Ressourcen der Schulen als verbesserungswürdig.`}</p>
      </div></div><div {...{
      "isBox": true,
      "flipKey": 137
    }}>
      <h2 parentName="div">{`Wie sind die privaten Grundschulen in Berlin verteilt?`}</h2>
      <p parentName="div">{`Private Grundschulen in Berlin sind nicht gleichmäßig über die Stadt verteilt. Es zeigt sich, dass in Nachbarschaften mit einer niedrigen Quote an Kinderarmut (0-10%) deutlich mehr private Grundschulen zu finden sind als in Nachbarschaften mit einem hohen Anteil an Kinderarmut (mehr als 50%).`}</p>
      <p parentName="div">{`Während in einigen Bezirken wie Charlottenburg-Wilmersdorf, Steglitz-Zehlendorf oder Pankow mehr als jede vierte Grundschule eine Privatschule ist, befindet sich in Bezirken wie Lichtenberg, Tempelhof-Schöneberg oder Neukölln nur jede zehnte Grundschule in privater Trägerschaft.`}</p>
      <p parentName="div">{`Um diese ungleiche Verteilung besser zu verstehen, haben wir uns angesehen, ob es einen Zusammenhang zwischen der Kinderarmut in den Nachbarschaften und der Anzahl privater Grundschulen in Berlin gibt. Nachbarschaften, die von einer hohen Kinderarmut betroffen sind, zeichnen sich dadurch aus, dass Kinder bis 15 Jahren in Familien leben, die staatliche Unterstützungsleistungen wie Arbeitslosengeld oder Hartz IV bekommen. In dieser Karte sieht man, wie hoch der Anteil der Kinderarmut in den einzelnen Nachbarschaften ist:`}</p>
      <p parentName="div"><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "726px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAADAAH/2gAMAwEAAhADEAAAAdKdViolBM//xAAaEAADAQADAAAAAAAAAAAAAAABAgMAEhNC/9oACAEBAAEFAr3PYK0Qi8yPXFcs1Xf/xAAVEQEBAAAAAAAAAAAAAAAAAAAAIf/aAAgBAwEBPwFX/8QAGBEBAAMBAAAAAAAAAAAAAAAAAQIQESH/2gAIAQIBAT8BYu8a/8QAHhAAAQQBBQAAAAAAAAAAAAAAAQACERIQITEyUWH/2gAIAQEABj8Co0xHSs4z4uQxsFo0L//EABoQAQADAQEBAAAAAAAAAAAAAAEAETEhUXH/2gAIAQEAAT8hNY2dYYpkSa3xciBSju8lxTV8gVENyf/aAAwDAQACAAMAAAAQ6A//xAAXEQADAQAAAAAAAAAAAAAAAAABECEx/9oACAEDAQE/EBNT/8QAFhEAAwAAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/EG6BU//EABsQAQADAAMBAAAAAAAAAAAAAAEAESExQVFh/9oACAEBAAE/EMRlCc/FrN8npBXVUvVUXGq0aEV5KaI3Qa7sUEk2iUuJ2BUU5Z//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/88bcf4223c114d01d606befe4029182c/ba381/image-02.webp 200w", "/static/88bcf4223c114d01d606befe4029182c/7f61c/image-02.webp 400w", "/static/88bcf4223c114d01d606befe4029182c/06b0e/image-02.webp 726w"],
              "sizes": "(max-width: 726px) 100vw, 726px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/88bcf4223c114d01d606befe4029182c/e07e9/image-02.jpg 200w", "/static/88bcf4223c114d01d606befe4029182c/066f9/image-02.jpg 400w", "/static/88bcf4223c114d01d606befe4029182c/fee13/image-02.jpg 726w"],
              "sizes": "(max-width: 726px) 100vw, 726px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/88bcf4223c114d01d606befe4029182c/fee13/image-02.jpg",
              "alt": "Anteil der Schüler an privaten Schulen",
              "title": "Anteil der Schüler an privaten Schulen",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p parentName="div">{`Daten: Senatsverwaltung für Gesundheit und Soziales (2015): Monitoring Soziale Stadtentwicklung 2015. Karte: Senatsverwaltung für Stadtentwicklung und Umwelt (2013): Lebensweltlich Orientierte Räume (LOR) in Berlin; Eigene Berechnungen und Darstellungen.`}</p>
      <div parentName="div" {...{
        "isReadmore": true,
        "flipKey": 35
      }}>
        <p parentName="div">{`Wir konnten feststellen, dass in Nachbarschaften mit einer niedrigen Quote an Kinderarmut (0-10%) deutlich mehr private Grundschulen zu finden sind als in Nachbarschaften mit einem hohen Anteil an Kinderarmut (mehr als 50%). Jedoch zeigt sich, dass der Standort einer Privatschule auch vom Immobilienmarkt und den verfügbaren Standorten beeinflusst wird, da es nur wenige Gebäude in der Stadt gibt, die für eine (Privat-)Schule geeignet sind. Schulen, die von der katholischen oder evangelischen Kirche betrieben werden, sind hingegen recht gleichmäßig über die Stadt verteilt. Dies könnte daran liegen, dass diese Schulen häufig an Gemeinden angebunden sind und auch auf Gebäude oder Grundstücke im Besitz der Kirche zurückgreifen können. Aus diesen beiden Gründen sind auch in Nachbarschaften mit einer hohen Kinderarmutsquote (40-50%) vergleichsweise viele Privatschulen anzutreffen:`}</p>
        <p parentName="div"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "679px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "62.5%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABcRAAAXEQHKJvM/AAACY0lEQVQ4y4VSy4oTURDN57jwB/wIxYWbAcGFC8GtxgfoxoVCcONiQBh3wihEUByEzIgbQ9Ik6UkmY+KISUg6ne5M0s9Mv5N0H2/dTDshLiwobvetuqdOnarMYrHAfD4HnWEYQpZluK7L3IPjuIiiiMUjHtv0eRQwv4gRTgZrRhe9Xo8BOaiKFUhDCYZmQpEVXjD1OF5yIONsDmvm8H+6J8vQx3g8hu/7WC6XGAwGCPwQd55s4fC4iu/tL/h2+HlVcBEyxnPGJkIQLfFrP4dJv4llwmJRiCRJkFFVFYVCgbcKJJyh5/q4lb0OsVFFvraNvPAaCw/QNQfT6Smk/m9Y7gKnO5dgnewhZC+9wEPMCGVarRba7TaazSY0TePAvhfg9qMbqDdFfKrvYK+2C93o4kjahWNOINeewQ1CaG+vwO4cQB2MIZ/8RJy2nA6GBrAC9HHz3lXO8IO4jY/Ce6izrxCsLQT2DFo9yxiFmL65DHsqYJp9CuPB/ZWG2DCSwHU8PMzdxXHrCAetd9hnGmpOFUfWYwSzM0x/vGSAAfT8NVhaHVbuFcwXz5lg54AkZuoBSyS2sjxCEidQFJUzN3QDlmnz4alsiHEcY8hyYpYznkzgsFXDJmBqNGnTNKHrOkajEd/LbrfLC3U6HQ4qSRIsy4JhGBgOh0hx/gEkINu2eTKtEy1sv9/nYFSI/hVF4XHKW23HBUZmvV1yaoXMPW+BANLBETMyz/N4nNin9hcQ/zEqsC5HaqRrep+S4BoSdWqTzvVvOhuNBkqlEsrlMgRBQKVS4ftaq9W4F4tFfi+KIteS3v0BadbFz1Tk/PEAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/326d2daf3f5970c54be3e1a01b61ead2/ba381/image-03.webp 200w", "/static/326d2daf3f5970c54be3e1a01b61ead2/7f61c/image-03.webp 400w", "/static/326d2daf3f5970c54be3e1a01b61ead2/8fbda/image-03.webp 679w"],
                "sizes": "(max-width: 679px) 100vw, 679px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/326d2daf3f5970c54be3e1a01b61ead2/772e8/image-03.png 200w", "/static/326d2daf3f5970c54be3e1a01b61ead2/e17e5/image-03.png 400w", "/static/326d2daf3f5970c54be3e1a01b61ead2/1b747/image-03.png 679w"],
                "sizes": "(max-width: 679px) 100vw, 679px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/326d2daf3f5970c54be3e1a01b61ead2/1b747/image-03.png",
                "alt": "Anteil der Schüler an privaten Schulen",
                "title": "Anteil der Schüler an privaten Schulen",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
      `}</picture>{`
    `}</span></p>
        <p parentName="div">{`Daten: Senatsverwaltung für Gesundheit und Soziales (2015): Monitoring Soziale Stadtentwicklung 2015 und Senatsverwaltung für Bildung, Jugend und Wissenschaft (2015)Schulfinder; Eigene Berechnungen und Darstellungen.`}</p>
        <p parentName="div">{`Ob es viele oder wenige Privatschulen in der Nachbarschaft gibt, beeinflusst auch, ob Eltern überhaupt eine Privatschule wählen, denn für viele ist ein kurzer Schulweg ausschlaggebend. Weitere Analysen der Elterninterviews zeigen außerdem, dass Eltern die Wahl einer Privatschule auch in Abhängigkeit von der Nachbarschaft und den dort lebenden Familien treffen. Zudem gibt es Hinweise darauf, dass einige Eltern bereits vor der Einschulung ihres Kindes in eine Nachbarschaft umziehen, in der es ein für sie passendes Schulangebot gibt.`}</p>
        <p parentName="div">{`Detailliertere Ergebnisse hierzu finden Sie in: Mayer, Tanja (2017). School Choice and the Urban Neighbourhood: Segregation Processes in the German Primary Sector with Special Reference to Private Schools. In: Koinzer, Thomas; Nikolai, Rita; Waldow, Florian (Hg.): Private Schools and School Choice in Compulsory Education. Wiesbaden: Springer Verlag, S.153-175. Online verfügbar unter: `}<a parentName="p" {...{
            "href": "https://link.springer.com/chapter/10.1007/978-3-658-17104-9_10"
          }}>{`https://link.springer.com/chapter/10.1007/978-3-658-17104-9`}{`_`}{`10`}</a></p>
      </div></div><div {...{
      "isBox": true,
      "flipKey": 138
    }}>
      <h2 parentName="div">{`Wie wählen Berliner Grundschulen ihre Schülerinnen und Schüler aus?`}</h2>
      <p parentName="div">{`In Berlin gilt für die öffentlichen Grundschulen das Wohnsitz- bzw. Sprengelprinzip, wodurch die zukünftigen Erstklässler verpflichtet sind, sich zunächst an der Grundschule ihres Einzugsgebiets anzumelden. Unsere Studie zeigt, dass diese gesetzliche Vorgabe in der Praxis der Aufnahme von Schülerinnen und Schülern an vielen Schulen eine untergeordnete Rolle zu spielen scheint. Nach Auskunft der befragten Schulleitungen ist bei der Aufnahme von Schülerinnen und Schülern der Wohnsitz nur für 58% und der Schulsprengel sogar nur für 32% der öffentlichen Schulen eine Voraussetzung. Zudem weisen die Ergebnisse unserer Befragung darauf hin, dass neben den privaten auch viele der öffentlichen Grundschulen in Berlin übernachfragt sind, weshalb die ‚Auswahl von Schülerinnen und Schülern in beiden Schulsegmenten eine Rolle spielt.`}</p>
      <div parentName="div" {...{
        "isReadmore": true,
        "flipKey": 36
      }}>
        <p parentName="div">{`Von den befragten öffentlichen Schulen geben 85% an, dass es Schülerinnen und Schüler an ihrer Schule gibt, die ursprünglich einer anderen Schule zugewiesen wurden. Diese Angabe lässt darauf schließen, dass der überwiegende Teil der öffentlichen Schulen Ausnahmen vom Sprengelprinzip zulässt. Erklärt werden kann dies u. a. mit dem erweiterten gesetzlichen Anspruch, wonach beim Vorliegen bestimmter Gründe, vom Sprengelprinzip abgesehen werden kann.`}</p>
        <p parentName="div">{`Privatschulen, die ihre Schülerinnen und Schüler auch ohne gesetzliche Vorgaben und Sprengelprinzip frei auswählen dürfen, nutzen in der Regel die Möglichkeit Gespräche mit den Familien zu führen. Aber auch von Bewerbungs- bzw. Motivationsschreiben oder Eingangstests wird Gebrauch gemacht. Öffentliche Schulen veranstalten hingegen vermehrt Probe- und Schnuppertage und steuern über ihr Schulprofil indirekt, welche Familien sich für ihre Schule interessieren und diese wählen. Zudem sind öffentliche Schulen in Berlin auch durch gesetzliche Vorgaben dazu aufgefordert, ein eigenes Schulprofil zu entwickeln. In der Folge haben sich auch in der öffentlichen Schullandschaft unterschiedliche pädagogische und inhaltliche Profile etabliert, wie z. B. Schulen mit reformpädagogischer, musischer oder sportbetonter Ausrichtung oder einem Fremdsprachen betonenden Profil. Dadurch können Familien neben den privaten Schulen auch zwischen öffentlichen Grundschulen wählen, die sich durch diese Profile voneinander unterscheiden.`}</p>
        <p parentName="div">{`Ein großer Teil der von uns befragten Schulleitungen öffentlicher Grundschulen nannte als einen ihrer Profilschwerpunkte den Bereich der Förderung (47%), worunter sowohl die individuelle Förderung der Schülerinnen und Schüler gefasst wird als auch die Begabtenförderung, Sprachförderung und Inklusion. Privatschulen verfolgen hingegen zu einem hohen Anteil (68%) reformpädagogische Ansätze. Der Schwerpunkt Kreativität, worunter die Betonung der Fächer Kunst, Theater und Musik fällt, wird sowohl von den öffentlichen (33%) als auch von den privaten Grundschulen (25%) genannt. Auch ein sprachliches Profil, in dessen Mittelpunkt der bilinguale Sachfachunterricht steht, wird von den befragten Privatschulen (11%) und öffentlichen Schulen (14%) verfolgt. Ein religiöses Schulprofil wird in Berlin jedoch nur von Privatschulen (36%) angeboten. Hier waren Mehrfachantworten möglich, da sich Schulen laut gesetzlicher Vorgaben mehrere Schwerpunkte geben können.`}</p>
      </div></div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      